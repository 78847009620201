import { render, staticRenderFns } from "./TablaDegradacionMensual.vue?vue&type=template&id=dca030ec&scoped=true&"
import script from "./TablaDegradacionMensual.vue?vue&type=script&lang=js&"
export * from "./TablaDegradacionMensual.vue?vue&type=script&lang=js&"
import style0 from "./TablaDegradacionMensual.vue?vue&type=style&index=0&id=dca030ec&scoped=true&lang=css&"
import style1 from "./TablaDegradacionMensual.vue?vue&type=style&index=1&id=dca030ec&lang=scss&scoped=true&"
import style2 from "./TablaDegradacionMensual.vue?vue&type=style&index=2&id=dca030ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dca030ec",
  null
  
)

export default component.exports